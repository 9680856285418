import { useState, useEffect } from "react";
import "./style.scss" // import du style


function Test() {
    const [tests, setTests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTests = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/tests`);
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des données');
                }
                const data = await response.json();
                setTests(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchTests();
    }, []);

    if (loading) return <p>Chargement...</p>;
    if (error) return <p>{error}</p>;
    return (
        <div className="Test">
            <h1>Liste des Tests</h1>
            <ul>
                {tests.map(test => (
                    <li key={test.id}>
                        ID: {test.id}, Name: {test.name}
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Test;