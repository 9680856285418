// import { useState } from 'react';
import "../../styles/portfolio.scss";
import { useState } from "react";
// J'importe le component Card depuis son emplacement dans le dossier Component/Card
import Card from "../../Components/Card/Card";
// J'importe les images pour les objets dans le tableau
import kasa from "../../images/kasa.png";
import shop from '../../images/shop1.png';
import team from '../../images/team.png';
import Form from "../../Components/Form/Form"


function Portfolio() {
    // création d'un tableau d'objet appelé "projects" en utilisant un useState
    const [projects, setProjects] = useState([
        // création du premier objet avec id/name/img/link/description position dans le tableau [0]
        {
            id: 1,
            name: "KASA",
            technologie: "REACT NodeJS",
            img: kasa,
            link: "https://kasadespapel.netlify.app",
            description: "Site de recherche immobilière",
        },
        {
            id: 2,
            name: "ESOU",
            technologie: "REACT NodeJS",
            img: shop,
            link: "https://projetdwwm.netlify.app/",
            description: "Site de vente de vêtements",
        },
        {
            id: 3,
            name: "TEAM",
            technologie: "HTML5 CSS3",
            img: team,
            link: "https://team9512.netlify.app",
            description: "Projet effectué lors de la formation",
        },
        
    ]);
    
    console.log(setProjects)

    // constante créer pour la barre de recherche avec le .filter
    const [search, setSearch] = useState("")

    // cette fonction récupère la valeur qui est écrit 
    const handleSearchName = (e) => {
        const value = e.target.value;
        setSearch(value);
    }

    return (
        <div className="Portfolio">
            <div className="search">
                <h3>Projets réalisés :</h3>
                <input
                    type="text"
                    name="searchBar"
                    id="searchBar"
                    placeholder="Nom du projet"
                    onChange={handleSearchName}
                />
            </div>
            <div className="list">

                {/* filtre le tableau projects qui cherche la value (name) pour le projet quand on écrit dans la barre de recherche */}
                {/* .map pour faire une boucle du tableau d'objet projects pour trouver le nom */}
                {projects.filter((val) => {
                    return val.name.toLowerCase().includes(search.toLowerCase())
                }).map((project) => (
                    // le component card est appelé
                    <Card project={project} />
                ))}
            </div>
            <Form />
        </div>
    );
}

export default Portfolio;
