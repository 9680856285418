import { useState } from 'react'; // import de useState
import "../../styles/compétences.scss" // import du style
import { ReactComponent as Star } from "../../images/étoile__remplie.svg"; //
import { ReactComponent as HalfStar } from "../../images/étoile__moitié__remplie.svg"; //
import { ReactComponent as EmptyStar } from "../../images/étoile__vide.svg"; // import de svg
import { ReactComponent as React } from "../../images/react.svg"; //
import { ReactComponent as NodeJS } from "../../images/node-js.svg"; //
import Form from "../../Components/Form/Form"


function Skills() {

    const [languages, setLanguages] = useState([
        {
            html: "HTML5",
            css: "CSS3",
            javascript: "JavaScript",
        }
    ]
    );

    const [technologies, setTechnologies] = useState([
        {
            react: <React className="svg__tech" />, // une fois que react est appelé le svg s'affiche
            nodejs: <NodeJS className="svg__tech" />,
        }
    ]
    );

    const [experiences, setExperience] = useState([
        {
            experience1: "J'ai effectué 1 mois de formation (piscine) à l'école 42",
            experience2: "Je réalise en ce moment un site e-commerce de vêtement homme et femme",
        }
    ]
    );

    console.log(setLanguages)
    console.log(setExperience)
    console.log(setTechnologies)

    return (
        <div className="Skills">

            {/* le .map fait une boucle pour récupérer le tableau languages qui contient un objet et on recupère un language pour l'afficher*/}
            {languages.map((language) => (
                <div className="languages">
                    <h4>
                        <label>LANGUAGES</label>
                    </h4>
                    <div className="html">
                        <p>
                            {/* language.html équivaut à "HTMl5" dans l'objet du tableau languages */}
                            <label>{language.html}</label>
                        </p>
                        <div className="stars">
                            <Star className="star__size" />
                            <Star className="star__size" />
                            <HalfStar className="star__size" />
                            <EmptyStar className="star__size" />
                            <EmptyStar className="star__size" />
                        </div>
                    </div>

                    <div className="css">
                        <p>
                            <label>{language.css}</label>
                        </p>
                        <div className="stars">
                            <Star className="star__size" />
                            <Star className="star__size" />
                            <HalfStar className="star__size" />
                            <EmptyStar className="star__size" />
                            <EmptyStar className="star__size" />
                        </div>
                    </div>

                    <div className="javascript">
                        <p>
                            <label>{language.javascript}</label>
                        </p>
                        <div className="stars">
                            <Star className="star__size" />
                            <Star className="star__size" />
                            <HalfStar className="star__size" />
                            <EmptyStar className="star__size" />
                            <EmptyStar className="star__size" />
                        </div>

                    </div>
                </div>
            ))}
            {technologies.map((technologie) => (
                <div className="technologies">
                    <h4>
                        <label>TECHNOLOGIES</label>
                    </h4>
                    <div className="tech">
                        <p>{technologie.react}</p>
                        <p>{technologie.nodejs}</p>
                    </div>
                </div>
            ))}
            {experiences.map((experience) => (
                <div className="experiences">
                    <h4>
                        <label>EXPÉRIENCES</label>
                    </h4>
                    <div className="exp">
                        <p>
                            <label>{experience.experience1}</label>
                        </p>
                        <p>
                            <label>{experience.experience2}</label>
                        </p>
                    </div>
                </div>
            ))}
            <div className='competences'>
                <h4>
                    <label>Compétences hors informatique</label>
                </h4>
                <ul className="ul__compétences">
                    <li>
                        <p>
                            <label>Lire des schémas électriques</label>
                        </p>
                    </li>
                    <li>
                        <p>
                            <label>Monter une armoire électrique ainsi que la câbler</label>
                        </p>
                    </li>
                    <li>
                        <p>
                            <label>Faire de la maintenance électrique en toute sécurité (EPI, ECS)</label>
                        </p>
                    </li>
                </ul>
            </div>
            <div className='hobbies'>
                <h4>
                    <label>Hobbies</label>
                </h4>
                <ul className="ul__hobbies">
                    <li>
                        <p>
                            <label>Jeux-vidéos : FPS, action-RPG, RTS</label>
                        </p>
                    </li>
                    <li>
                        <p>
                            <label>
                                Apprendre de nouvelles choses, comme le codage
                            </label>
                        </p>
                    </li>
                    <li>
                        <p>
                            <label>
                                Sport : Musculation niveau intermédiaire
                            </label>
                        </p>
                    </li>
                </ul>
            </div>
            <Form />
        </div>
    );
};

export default Skills;