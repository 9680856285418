// Import du Link pour faire fonctionner le lien sur les site
import { Link } from 'react-router-dom'
import "../../styles/card.scss"

// Je définis props entre les parentheses afin de pouvoir recuperer 
//les parametres qui sont passés lors de l'appel de mon component sur 
//la page Portfolio
function Card(props) {

    // Cela me permet de recuperer dans 1 variable le parametre envoyé 
    //lors de l'appel de mon component, donc dans Portfolio, l'objet de ma boucle
    const { project } = props

    return (
        <div className="Card">
            {/* Puis je peux utiliser project vu que je l'ai récupéré */}
            <Link to={project.link} className="card" key={project.id} target='_blank' >
                <img className='img__site' src={project.img} alt="du site" />
                <div>
                    <h2>{project.name}</h2>
                    <h5>{project.description}</h5>
                    <h7> Fais en {project.technologie}</h7>
                </div>
            </Link>
            <div>

            </div>
        </div>
    )
}


export default Card;