import "../../styles/home.scss"; // import du scss
import "@fontsource/montserrat"; // Defaults to weight 400
import "@fontsource/montserrat/400.css"; // Specify weight
import "@fontsource/montserrat/400-italic.css"; // Specify weight and style
import { ReactComponent as HomeFront } from "../../images/code-solid.svg"; //
import { ReactComponent as HomeBack } from "../../images/database-solid.svg"; // import de svg
import { ReactComponent as HomeGlobe } from "../../images/globe-solid.svg"; //
import { ReactComponent as HomeMobile } from "../../images/cell-phone.svg"; //
import profil from "../../images/img.jpg" // import de la photo de profil
// import Form from "../../Components/Form/Form"


function Home() {


    return (
        <div className="Home">
            <div className="top__home">
                <div className="intro">
                    <h2><label>Bonjour, je m'appelle Damien. Actuellement en formation de</label></h2>
                    <h1><label>Développeur web</label></h1>
                    <h3>
                        <label>Je recherche un <span>stage de 2 mois</span> à Paris et
                            alentours <span>du 13 mai au 5 juillet 2024</span></label>
                    </h3>
                </div>

                <div className="top__information">
                    <div>
                        <HomeFront className="color__svg--front svg__size" />
                        <label>front-end</label>
                    </div>
                    <div>
                        <HomeBack className="color__svg--back svg__size" />
                        <label>back-end</label>
                    </div>
                    <div>
                        <HomeMobile className="color__svg--mobile svg__size" />
                        <label>mobile</label>
                    </div>
                    <div>
                        <HomeGlobe className="color__svg--anglais svg__size" />
                        <label>anglais</label>
                    </div>
                </div>

            </div>

            <div className="infos">

                <div className="photo">
                    <div className="picture">
                        <img className="profil" src={profil} alt="de Damien"></img>
                    </div>
                    <h1>
                        <label>Damien REGNAULT</label>
                    </h1>
                    <h5>
                        <label>Age :<br /> 29 ans</label>
                    </h5>
                    <h5>
                        <label>Ville :<br /> Ermont (95120)</label>
                    </h5>
                </div>

                <div className="description">
                    <h1>
                        <label>À propos de moi</label>
                    </h1> <br />
                    <h2>
                        <label>Possédant de bonnes bases théoriques dans le domaine du développement Web,
                            doté d’un esprit logique, passionné des nouvelles technologies et animé par ce
                            désir perpétuel d’apprendre de nouvelles connaissances, je me suis lancé dans
                            un projet professionnel qui me tiens cœur. Actuellement en formation développement
                            web, je vous propose de mettre en pratique mon savoir dans votre entreprise.</label>
                    </h2>
                </div>
            </div>
            {/* <Form /> */}
        </div>
    );
};

export default Home;
