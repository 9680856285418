import React, { useState } from 'react';
import '../../styles/form.scss'; // Fichier CSS pour les styles
import { useForm } from 'react-hook-form'
import { ReactComponent as Cross } from "../../images/x.svg"; //


const Form = () => {
  const { handleSubmit, register, reset, formState: { errors } } = useForm()
  const [showForm, setShowForm] = useState(false);

  const onSubmit = (data) => {
    console.log(data)

    if (handleSubmit) { // si envoie du formulaire alors
      alert("Votre message a été envoyé avec succés"); // alerte enclenché
    }
    fetch("http://localhost:3001/contact", { // Post lastName/firstName etc.. 
      method: "POST",                      // dans le back pour envois de l'email
      headers: {                           // et le stocker dans mongoDB
        "Content-Type": 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Create Success: ", data);
      })
      .catch((error) => {
        console.error("ERROR: ", error);
      })
    reset() // reset les inputs 
  }

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  return (
    <div className="Form">
      <button className='button__msg' onClick={toggleForm}></button>
      {showForm && (
        <div className="form__container">
          {/* Formulaire de contact */}
          <form className='form' onSubmit={handleSubmit(onSubmit)}>
            <button onClick={toggleForm} type='quit' className='button__cross'>
              <Cross className="svg__cross" />
            </button>
            <div className="names">
              <div className="lastname">
                <label htmlFor="lastname">Nom</label>
                <input
                  type="text"
                  name="lastname"
                  id="lastname"
                  placeholder=' Nom'
                  // grâce à useForm, on enregistre dans data le lastname/firstname etc..
                  {...register("lastName", {
                    // restrictions par le validate :
                    validate: {
                      //  Rend obligatoire 2 caractères minimum 
                      minLength: (v) => v.length >= 2 || "Minimum 2 caractères",
                      // pattern qui autorise que les lettres en majuscule et en minuscule
                      matchPattern: (v) => /^[a-zA-Z]+$/.test(v) || "Que des lettres",
                    },
                  })}
                />
                {/* Affiche un message selon l'erreur de l'utilisateur selon les restrictions qui sont écrite au dessus */}
                {errors.lastName?.message && (
                  <small>{errors.lastName.message}</small>
                )}
              </div>
              <div className="firstname">
                <label htmlFor="firstname">Prénom</label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  placeholder=' Prénom'
                  {...register("firstName", {
                    validate: {
                      minLength: (v) => v.length >= 2 || "Minimum 2 caractères",
                      matchPattern: (v) => /^[a-zA-ZÀ-ÿ]+$/.test(v) || "Que des lettres",
                    },
                  })}
                />
                {errors.firstName?.message && (
                  <small>{errors.firstName.message}</small>
                )}
              </div>
            </div>
            <div className="email">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                id="email"
                placeholder=' exemple@wanadoo.fr'
                {...register("email", {
                  required: "Email requis",
                  validate: {
                    matchPattern: (v) =>
                      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                      "L'email doit être valide",
                    minLength: (v) =>
                      v.length >= 6 || "Minimum 6 caractères",
                  },
                })}
              />
              {errors.email?.message && (
                <small>{errors.email.message}</small>
              )}
            </div>
            <div className="text">
              <div className="subject">
                <label htmlFor="subject">Sujet</label>
                <input
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder=' Sujet du message'
                  {...register("subject", {
                    required: "Sujet requis", validate: {
                      minLength: (v) =>
                        v.length >= 5 || "Minimum 5 caractères",
                    },
                  })}
                />
                {errors.subject?.message && (
                  <small>{errors.subject.message}</small>
                )}
              </div>
              <div className="message">
                <label htmlFor="msg">Message</label>
                <textarea
                  type="text"
                  name="msg"
                  id="msg"
                  placeholder=' Quel est votre message ?'
                  {...register("msg", {
                    required: "Message requis", validate: {
                      minLength: (v) =>
                        v.length >= 10 || "Minimum 10 caractères",
                    },
                  })}
                ></textarea>
                {errors.msg?.message && (
                  <small>{errors.msg.message}</small>
                )}
              </div>

            </div>
            <div className="buttons">
              <button type="reset" className='button__cancel'>Annuler</button>
              <button type="submit" className="button__send">Envoyer</button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Form;
