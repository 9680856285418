import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home/Home';
import Compétences from './Pages/Compétences/Compétences';
// import Contact from './Pages/Contact/Contact';
import Portfolio from './Pages/Portfolio/Portfolio';
import Test from './Pages/Test/Test';
import "./index.scss";
import "@fontsource/montserrat"; 
import "@fontsource/montserrat/400.css"; 

function App() {
  return (
    <BrowserRouter basename="/">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/compétences" element={<Compétences />} />
        <Route path="/test" element={<Test />} />
        <Route path="/portfolio" element={<Portfolio />} />
        {/* <Route path="/contact" element={<Contact />} /> */}
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
