import { NavLink } from "react-router-dom"; // import de NavLink pour naviguer entre chaque page
import "../../styles/header.scss" // import du scss pour la mise en page
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' // import de la fonctionnalité de fontawesome
import { faHouse } from '@fortawesome/free-solid-svg-icons' // import de l'icône House

const Header = () => {
    return (
        <div className="Header">
            <div className="acceuil">
                <NavLink to="/" className="hover"><FontAwesomeIcon className="house" icon={faHouse} /></NavLink>{/* Appuie sur l'icône */}
            </div>

            <div className="compétences">
                <NavLink className="hover" to="/compétences">Compétences</NavLink>
            </div>

            <div className="portfolio">
                <NavLink className="hover" to="/portfolio">Portfolio</NavLink>
            </div>

            {/* <div className="contact">
                <NavLink className="hover margin__right" to="/contact">Contact</NavLink>
            </div> */}
        </div>
    );
};

export default Header;